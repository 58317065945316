<template>
  <div class="container">
    <div class="logo-big"></div>
    <div class="main">
      <div class="logo"></div>
      <el-form :model="loginData">
        <el-form-item :label="fun.getLanguageText($store.state.language, '账号名称')">
          <el-input v-model="loginData.username" class="u" placeholder="请输入账号"></el-input>
        </el-form-item>
        <el-form-item :label="fun.getLanguageText($store.state.language, '账号密码')">
          <el-input v-model="loginData.password" class="p" placeholder="请输入密码" show-password></el-input>
        </el-form-item>
        <el-select v-model="lang" @change="change">
          <el-option label="中文" :value="1">
          </el-option>
          <el-option label="English" :value="2">
          </el-option>
        </el-select>
        <el-form-item class="btn-login">
          <el-button type="primary" @click="login">{{ fun.getLanguageText($store.state.language, '登录') }}</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      lang: 1,
      loginData: {
        username: '',
        password: '',

      }
    }
  },
  created() {
    if (localStorage.getItem('language') == 2) {
      this.lang = 2
      localStorage.setItem('language', '2')
    } else {
      this.lang = 1
      this.$store.state.language = 1
    }
  },
  methods: {
    // 修改语言
    change(e) {
      console.log(e)
      if (e === 1) {
        this.$store.state.language = 1
        localStorage.setItem('language', '1')
        location.reload()
      }
      if (e === 2) {
        this.$store.state.language = 2
        localStorage.setItem('language', '2')
        location.reload()
      }
    },
    login() {
      if (this.loginData.username === '' || this.loginData.password === '') {
        this.$message({
          message: '账号密码不得为空',
          type: 'warning',
          duration: 5000
        });
        return
      }
      this.$http.post('user/login', {
        name: this.loginData.username,
        password: this.loginData.password
      }).then(res => {
        // 登录成功
        console.log(res.data)
        if (res.data.msg === '登录成功') {
          // 保存token
          localStorage.setItem('u_token', res.data.data.token)
          localStorage.setItem('username', res.data.data.name)
          localStorage.setItem('role', res.data.data.role)
          let role = localStorage.getItem('role')
          if (role === 'admin') {
            this.$store.state.roleId = 2
          }
          if (role === 'customer') {
            this.$store.state.roleId = 3
          }
          if (role === 'client') {
            this.$store.state.roleId = 4
          }
          if (role === 'super') {
            this.$store.state.roleId = 1
          }
          // 跳转页面
          this.$router.push('/Home/Home')
          // 弹出提示
          this.$message({
            message: this.fun.getLanguageText(this.$store.state.language, '登录成功:') + res.data.data.name,
            type: 'success',
            duration: 5000
          });
          return
        }
        this.$message({
          message: res.data.msg,
          type: 'warning',
          duration: 5000
        });
      }).catch(err => {
        // 请求失败
        this.$message({
          message: "登录失败,请稍后重试",
          type: 'warning',
          duration: 5000
        });
      })
    }
  }
};
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  height: 100%;
  display: flex;
}

.logo-big {
  background-image: url(../../assets/login.jpg);
  background-repeat: no-repeat;
  flex: 1;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
}

.main {
  width: 500px;
  height: 100%;
  background: #FFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.logo {
  width: 270px;
  height: 80px;
  margin-bottom: 100px;
  background-image: url(../../assets/LOGO1.jpg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
}

.el-form {
  width: 300px;
  height: 360px;
  background: #cccccc88;
  padding-left: 30px;
  padding-right: 30px;
}

.el-select {
  width: 300px;
}

.el-button {
  width: 100%;
}

.btn-login {
  margin-top: 48px;
}
</style>
